import { Modal } from "antd";
import {
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
  TwitterOutlined,
  FacebookOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import ChangeProfileCard from "../../components/Settings/Personal Settings/ChangeProfile";

const maskLink = (link, type) => {
  switch (type) {
    case "phone":
      return link.replace(/(\d{3})(\d{3})(\d{3})(\d{1})/, "+*** *** *** *");
    case "email":
      const [name, domain] = link.split("@");
      return `${name[0]}***@${domain}`;
    case "url":
      return link.replace(/(https?:\/\/)?(www\.)?/, "").split("/")[0] + "...";
    default:
      return link;
  }
};

const CountryContacts = ({ isOpen, onOkay, onCancel, selectedCountry }) => {
  if (!selectedCountry) return null;

  const handlePhoneClick = () => {
    window.location.href = `tel:${selectedCountry.phone}`;
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${selectedCountry.email}`;
  };

  const handleWebsiteClick = () => {
    window.open(selectedCountry.websiteLink, "_blank");
  };

  const handleTwitterClick = () => {
    window.open(selectedCountry.twitterLink, "_blank");
  };

  const handleFacebookClick = () => {
    window.open(selectedCountry.facebookLink, "_blank");
  };

  const handleLinkedInClick = () => {
    window.open(selectedCountry.linkedinLink, "_blank");
  };

  const countryDetails = [
    selectedCountry.phone && {
      iconOne: <PhoneOutlined className="text-[#E5791B] text-[24px]" />,
      title: "Phone",
      description: selectedCountry.phone,
      onCardClick: handlePhoneClick,
    },
    selectedCountry.email && {
      iconOne: <MailOutlined className="text-[#E5791B] text-[24px]" />,
      title: "Email",
      description: selectedCountry.email,
      onCardClick: handleEmailClick,
    },
    selectedCountry.websiteLink && {
      iconOne: <GlobalOutlined className="text-[#E5791B] text-[24px]" />,
      title: "Website",
      description: maskLink(selectedCountry.websiteLink, "url"),
      onCardClick: handleWebsiteClick,
    },
    selectedCountry.twitterLink && {
      iconOne: <TwitterOutlined className="text-[#E5791B] text-[24px]" />,
      title: "Twitter",
      description: maskLink(selectedCountry.twitterLink, "url"),
      onCardClick: handleTwitterClick,
    },
    selectedCountry.facebookLink && {
      iconOne: <FacebookOutlined className="text-[#E5791B] text-[24px]" />,
      title: "Facebook",
      description: maskLink(selectedCountry.facebookLink, "url"),
      onCardClick: handleFacebookClick,
    },
    selectedCountry.linkedinLink && {
      iconOne: <LinkedinOutlined className="text-[#E5791B] text-[24px]" />,
      title: "LinkedIn",
      description: maskLink(selectedCountry.linkedinLink, "url"),
      onCardClick: handleLinkedInClick,
    },
  ].filter(Boolean); // Filter out any undefined values

  return (
    <Modal
      title={<p className="text-center">Support</p>}
      open={isOpen}
      onOk={onOkay}
      onCancel={onCancel}
      footer={null}
      className="w-11/12 md:w-3/5"
    >
      <>
        <div className="mb-5">
          <p className="text-lg font-semibold mb-1">How can we help you?</p>
          <p className="text-[#929497] text-left">
            Reach out to us any time you need help and our talented team will
            gladly assist you.
          </p>
        </div>

        <div className="space-y-4">
          {countryDetails.map((detail, index) => (
            <ChangeProfileCard
              key={index}
              iconOne={detail.iconOne}
              title={detail.title}
              description={detail.description}
              onCardClick={detail.onCardClick}
            />
          ))}
        </div>
      </>
    </Modal>
  );
};

export default CountryContacts;
