import React, { createContext, useCallback, useContext, useState } from "react";
import { useNotification } from "../notification-context";
import { postAuthenticatedRequest } from "../../../utils/api/requests/request";
import { cleanObject } from "../../../utils/helper/format";

const CustomerContext = createContext();

export const CustomerProvider = ({ children }) => {
  const triggerNotification = useNotification();
  const [allCustomers, setAllCustomers] = useState(null);
  const [isLoanDetailsLoading, setIsLoanDetailsLoading] = useState(false);
  const [isAllCustomerLoading, setIsAllCustomerLoading] = useState(false);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 50,
    status: "",
    customerType: null,
    searchText: "",
    startDate: "",
    endDate: "",
  });
  const [loanQuoteDetails, setLoanQuoteDetails] = useState(null);
  const [nextOfKin, setNextOfKin] = useState(null);
  const [approvalInformation, setApprovalInformation] = useState(null);
  const [employmentInformation, setEmploymentInformation] = useState(null);
  const [basicInformation, setBasicInformation] = useState(null);
  const [guarantors, setGuarantors] = useState(null);
  const [activeModal, setActiveModal] = useState(null);

  const openModal = (modalName) => {
    setActiveModal(modalName);
  };

  const closeModal = () => {
    setActiveModal(null);
  };
  const getAllCustomers = useCallback(async () => {
    try {
      setIsAllCustomerLoading(true);

      // Filter out properties with empty values
      const cleanedFilter = cleanObject(filter);

      const response = await postAuthenticatedRequest(
        "RetrieveSelfServiceCustomersQuery",
        cleanedFilter
      );

      const result = response?.outData;
      if (result?.isSuccessful) {
        setAllCustomers(result?.data);
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "Failed to fetch user data",
      });
    } finally {
      setIsAllCustomerLoading(false);
    }
  }, [triggerNotification, filter]);

  // API call function to get loan details by ID
  const getLoanQuoteDetails = useCallback(
    async (loanQuoteId) => {
      try {
        setIsLoanDetailsLoading(true);
        const response = await postAuthenticatedRequest(
          "GetLoanQuoteWithDetailsQuery",
          { loanQuoteId }
        );

        const result = response?.outData;
        if (result?.isSuccessful) {
          const loanQuoteDetails = result?.data;
          setBasicInformation({
            firstName: loanQuoteDetails?.userDetail?.firstName,
            lastName: loanQuoteDetails?.userDetail?.lastName,
            emailAddress: loanQuoteDetails?.userDetail?.emailAddress,
            mobileNumber: loanQuoteDetails?.userDetail?.mobileNumber,
            mobileCountryCode: loanQuoteDetails?.userDetail?.mobileCountryCode,
            accountType: loanQuoteDetails?.userDetail?.userType,
            dateOfBirth: loanQuoteDetails?.userDetail?.dateOfBirth,
            houseAddress: "- - -",
            primaryAccount: "- - -",
          });
          setLoanQuoteDetails({
            id: loanQuoteDetails?.loanQuote?.id,
            productName: "- - -",
            loanAmount: loanQuoteDetails?.loanQuote?.loanAmount,
            loanType: "- - -",
            interestRate: loanQuoteDetails?.loanQuote?.interestRate,
            loanStatus: "- - -",
          });
          setNextOfKin({
            name: loanQuoteDetails?.nextOfKin?.name,
            relationship: loanQuoteDetails?.nextOfKin?.relationship,
            mobileNumber: loanQuoteDetails?.nextOfKin?.mobileNumber,
            emailAddress: loanQuoteDetails?.nextOfKin?.emailAddress,
            houseAddress: loanQuoteDetails?.nextOfKin?.address,
          });
          setApprovalInformation({
            approvalDescription:
              loanQuoteDetails?.approvalInformation?.approvalDescription,
            showApproveButton:
              loanQuoteDetails?.approvalInformation?.showApproveButton,
            showRejectButton:
              loanQuoteDetails?.approvalInformation?.showRejectButton,
            showReturnButton:
              loanQuoteDetails?.approvalInformation?.showReturnButton,
          });

          setEmploymentInformation({
            jobTitle: loanQuoteDetails?.employmentInformation?.jobTitle,
            employmentType: "- - -",
            employmentNumber: "- - -",
            employmentStartDate:
              loanQuoteDetails?.employmentInformation?.employmentStartDate,
            employerName: loanQuoteDetails?.employmentInformation?.employerName,
            employerAddress: "- - -",
            officialEmailAddress:
              loanQuoteDetails?.employmentInformation?.officialEmailAddress,
            salaryAccountName:
              loanQuoteDetails?.employmentInformation?.salaryAccountName,
            salaryAccountNumber:
              loanQuoteDetails?.employmentInformation?.salaryAccountNumber,
            salaryBankCode:
              loanQuoteDetails?.employmentInformation?.salaryBankCode,
            salaryPaymentDay:
              loanQuoteDetails?.employmentInformation?.salaryPaymentDay,
          });
          setGuarantors(loanQuoteDetails?.guarantors);
        } else {
          triggerNotification({
            type: "error",
            message: "Error",
            description: result?.message,
          });
        }
      } catch (error) {
        triggerNotification({
          type: "error",
          message: "Error",
          description: error?.message || "Failed to fetch user data",
        });
      } finally {
        setIsLoanDetailsLoading(false);
      }
    },
    [triggerNotification]
  );

  // API call function to get user details by ID
  const getUserDetails = useCallback(
    async (userId) => {
      try {
        setIsLoanDetailsLoading(true);
        const response = await postAuthenticatedRequest(
          "GetUserWithDetailsQuery",
          { userId }
        );

        const result = response?.outData;
        if (result?.isSuccessful) {
          const userDetails = result?.data;

          console.log("userDetails", userDetails);
          // setBasicInformation({
          //   firstName: loanQuoteDetails?.userDetail?.firstName,
          //   lastName: loanQuoteDetails?.userDetail?.lastName,
          //   emailAddress: loanQuoteDetails?.userDetail?.emailAddress,
          //   mobileNumber: loanQuoteDetails?.userDetail?.mobileNumber,
          //   mobileCountryCode: loanQuoteDetails?.userDetail?.mobileCountryCode,
          //   accountType: loanQuoteDetails?.userDetail?.userType,
          //   dateOfBirth: loanQuoteDetails?.userDetail?.dateOfBirth,
          //   houseAddress: "- - -",
          //   primaryAccount: "- - -",
          // });
        } else {
          triggerNotification({
            type: "error",
            message: "Error",
            description: result?.message,
          });
        }
      } catch (error) {
        triggerNotification({
          type: "error",
          message: "Error",
          description: error?.message || "Failed to fetch user data",
        });
      } finally {
        setIsLoanDetailsLoading(false);
      }
    },
    [triggerNotification]
  );

  return (
    <CustomerContext.Provider
      value={{
        allCustomers,
        isAllCustomerLoading,
        isLoanDetailsLoading,
        getAllCustomers,
        getUserDetails,
        getLoanQuoteDetails,
        filter,
        setFilter,
        basicInformation,
        loanQuoteDetails,
        nextOfKin,
        approvalInformation,
        employmentInformation,
        guarantors,
        activeModal,
        setActiveModal,
        openModal,
        closeModal,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomer = () => {
  return useContext(CustomerContext);
};
