import OtpInput from "react-otp-input";
import { Button, Form } from "antd";
import { useOtp } from "../../store/context/otp-context";
import { useState } from "react";
import { useTheme } from "../../store/context/theme-context";

const containerStyle = {
  display: "flex",
  justifyContent: "start",
  gap: "8px",
};

const inputStyle = {
  width: "35px",
  height: "35px",
  fontSize: "14px",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "#ccc",
  borderRadius: "4px",
  textAlign: "center",
  outline: "none",
  transition: "border-color 0.3s",
};

const inputFocusStyle = {
  ...inputStyle,
  borderColor: "#E5791B",
  boxShadow: "0 0 5px rgba(163, 42, 41, 0.5)",
};

const darkInputStyle = {
  ...inputStyle,
  borderColor: "#555",
  backgroundColor: "#333",
  color: "#fff",
};

const darkInputFocusStyle = {
  ...darkInputStyle,
  borderColor: "#E5791B",
  boxShadow: "0 0 5px rgba(163, 42, 41, 0.5)",
};

const VerifyOTP = ({ handleSubmit, handleResend, formData, isLoading }) => {
  const [form] = Form.useForm();
  const { theme } = useTheme();
  const { otp, setOtp, otpError, seconds, isResendDisabled } = useOtp();

  const [focusedInput, setFocusedInput] = useState(null);

  const isDarkMode = theme === "dark";

  return (
    <div className="flex flex-col items-center justify-center gap-3 w-full">
      <div className="mt-4 mb-6">
        <p className="text-lg font-semibold">
          We have sent a verification code to <br />
          {formData && formData.emailAddress
            ? `${formData.emailAddress.slice(
                0,
                2
              )}******${formData.emailAddress.slice(
                formData.emailAddress.indexOf("@") - 2
              )}`
            : "your email address."}
        </p>

        <p className="text-base text-[#929497] mb-5">
          Please enter the code below.
        </p>

        <div
          style={{ maxWidth: "150px", margin: "0 auto", textAlign: "center" }}
        >
          <img src={"/2fa.svg"} alt="Verification" style={{ width: "100%" }} />
        </div>
      </div>

      <Form form={form} layout="vertical">
        <div className="mt-4 flex justify-center">
          <Form.Item
            label=""
            name="otp"
            rules={[
              {
                required: true,
                message: "Please enter the OTP.",
              },
            ]}
          >
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props, index) => (
                <input
                  {...props}
                  height={"80px !important"}
                  onFocus={() => setFocusedInput(index)}
                  onBlur={() => setFocusedInput(null)}
                  style={
                    focusedInput === index
                      ? isDarkMode
                        ? darkInputFocusStyle
                        : inputFocusStyle
                      : isDarkMode
                      ? darkInputStyle
                      : inputStyle
                  }
                />
              )}
              containerStyle={containerStyle}
            />
          </Form.Item>
        </div>

        <p className="text-red-800 my-3 text-center">{otpError}</p>
        <div className="flex flex-col md:flex-row gap-1 mb-4">
          <Button
            type="primary"
            htmlType="submit"
            className="shadow-none px-3 py-2 w-full h-full text-base"
            onClick={handleSubmit}
            disabled={otp.length < 6}
            loading={isLoading}
          >
            Verify
          </Button>
          <Button
            type="default"
            htmlType="button"
            onClick={handleResend}
            disabled={isResendDisabled}
            className="px-3 py-2 w-full h-full text-base"
          >
            {isResendDisabled
              ? ` Resend in 00:${seconds < 10 ? `0${seconds}` : seconds}`
              : "Resend"}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default VerifyOTP;
