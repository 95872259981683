import React, { createContext, useCallback, useContext, useState } from "react";
import { useNotification } from "../notification-context";
import { postAuthenticatedRequest } from "../../../utils/api/requests/request";
import { cleanObject } from "../../../utils/helper/format";

const TransferContext = createContext();

export const TransferProvider = ({ children }) => {
  const [transfers, setTransfers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const triggerNotification = useNotification();
  const [filter, setFilter] = useState({
    pageNumber: 1,
    pageSize: 50,
    requestStatus: "",
    searchText: "",
    startDate: "",
    endDate: "",
  });

  const getTransfers = useCallback(async () => {
    try {
      setIsLoading(true);

      // Filter out properties with empty values
      const cleanedFilter = cleanObject(filter);
      const response = await postAuthenticatedRequest(
        "GetTransferRequestsQuery",
        {
          operation: "GET",
          requestType: "TRANSFER",
          ...cleanedFilter,
        }
      );

      const result = response?.outData;
      if (result?.isSuccessful) {
        setTransfers(result?.data.transfers);
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "Failed to fetch transfers",
      });
    } finally {
      setIsLoading(false);
    }
  }, [triggerNotification, filter]);

  return (
    <TransferContext.Provider
      value={{ transfers, isLoading, getTransfers, filter, setFilter }}
    >
      {children}
    </TransferContext.Provider>
  );
};

export const useTransfer = () => {
  return useContext(TransferContext);
};
