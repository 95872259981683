import axios from "axios";
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  getUserId
} from "./localStorageService";

const axiosInstance = axios.create({
  baseURL: "https://apitesting.monee.ng/Fintech.CBS.Backend",
  headers: { "Content-Type": "application/json" }, //optional: default headers
});

// set up request interceptor
// if there is an access token, set the Authorization header with the token
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// set up response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const refreshToken = getRefreshToken();
    const userId = getUserId();
    const originalRequest = error?.config;

    if (error?.response?.status === 401 && !refreshToken) {
      // No refreshToken, redirect to login page
      // No refreshToken, redirect to login page
      localStorage.clear();
      localStorage.setItem("isLoggedIn", false);
      localStorage.setItem("logoutEvent", Date.now());
      window.location.href = "/authentication?mode=signin";
      // console.log("No refresh token");
      return Promise.reject(error);
    }

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const dataToSend = {
          cmd: "LoginRefreshTokenCommand",
          data: JSON.stringify({
            userId,
            refreshToken,
          }),
        };

        const response = await axiosInstance.post("/api/cmd/cmd", dataToSend);

        const newAccessToken = response?.data?.outData?.accessToken;

        if (newAccessToken) {
          setAccessToken(newAccessToken);
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        } else {
          // If the refresh token is invalid or expired, redirect to login and clear session data
          localStorage.clear();
          localStorage.setItem("isLoggedIn", false);
          localStorage.setItem("logoutEvent", Date.now());
          window.location.href = "/authentication?mode=signin";
          // console.log("Invalid refresh token");
          return Promise.reject(error);
        }
      } catch (refreshError) {
        // If the refresh token is invalid or expired, redirect to login and clear session data
        localStorage.clear();
        localStorage.setItem("isLoggedIn", false);
        localStorage.setItem("logoutEvent", Date.now());
        window.location.href = "/authentication?mode=signin";
        // console.log("Refresh token request failed");
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

// export the axios instance
export default axiosInstance;
