import { postRequest } from "../requests/request";

// Function to preregister a user
export const preRegisterUser = async (data) => {
  return postRequest( "PreRegistrationCommand", data);
};

// Function to validate OTP
export const validateOTP = async (data) => {
  return postRequest( "ValidateOtpCommand", data);
};

export const preRegisterCorporateUser = async (data) => {
  return postRequest( "PreCoorporateRegistrationCommand", data);
};

// Function to resend OTP
export const resendOTP = async (data) => {
  return postRequest("ResendOtpCommand", data);
};

// Function to complete registration
export const completeRegistration = async (data) => {
  return postRequest( "CompleteRegistrationCommand", data);
};

// Function to login a user
export const loginUser = async (data) => {
  return postRequest( "SelfLoginCommand", data);
};

// Function to initiate password reset
export const initiateResetPassword = async (data) => {
  return postRequest( "InitiatePasswordResetCommand", data);
};

// Function to complete password reset
export const completePasswordReset = async (data) => {
  return postRequest( "CompletePasswordResetCommand", data);
};
