import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { fetchPolicies } from '../redux/actions/policyActions';
import { getSessionData, setSessionData } from '../../utils/interceptors/localStorageService';
import { postAuthenticatedRequest } from '../../utils/api/requests/request';
import { useNotification } from './notification-context';

export const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
    const [selectedFilter, setSelectedFilter] = useState('deposits');
    const [savedSessionData, setSavedSessionData] = useState(null);
    const [policiesLoaded, setPoliciesLoaded] = useState(false);
    const [depositPolicies, setDepositPolicies] = useState([]);
    const { policies, loading, error } = useSelector((state) => state.policy);
    const dispatch = useDispatch();
    const triggerNotification = useNotification();
    const navigate = useNavigate();

    useEffect(() => {
        const storedSessionData = getSessionData();
        if (storedSessionData) {
            setSavedSessionData(storedSessionData);
        }
        dispatch(fetchPolicies());
    }, [dispatch]);


    useEffect(() => {
        console.log("loading changed ?? ", loading)
        // setPoliciesLoaded()
    }, [loading])



    useEffect(() => {
        if (policies) {
            const filteredDeposits = policies.filter(policy => policy.type === 'deposits');
            setDepositPolicies(filteredDeposits);
        }
    }, [policies]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await postAuthenticatedRequest('UserProfileQuery', { operation: 'POST' });
                const result = response?.outData;
                if (result?.isSuccessful) {
                    const userData = { ...savedSessionData, ...result?.data };
                    setSessionData(userData);
                    setSavedSessionData(userData);
                    sessionStorage.removeItem('shouldRefreshUserData');
                } else {
                    triggerNotification({ type: 'error', message: 'Error', description: result.message });
                }
            } catch (error) {
                triggerNotification({ type: 'error', message: 'Error', description: error.message });
            }
        };

        if (!savedSessionData) {
            navigate('/authentication?mode=signin');
        } else if (!savedSessionData?.mobileNumber || sessionStorage.getItem('shouldRefreshUserData') === 'true') {
            fetchUserData();
        }

        const handleBeforeUnload = () => {
            sessionStorage.setItem('shouldRefreshUserData', 'true');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [savedSessionData, triggerNotification]);

    return (
        <DashboardContext.Provider value={{
            selectedFilter,
            setSelectedFilter,
            savedSessionData,
            depositPolicies,
            policies,
            loading,
            error
        }}>
            {children}
        </DashboardContext.Provider>
    );
};
