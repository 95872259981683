import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { ConfigProvider, theme, App, notification } from "antd";
import { useTheme } from "./store/context/theme-context";
import { getSubdomain } from './utils/routes/baseURLRetriver'; // Adjust the path as necessary

import { OtpProvider } from "./store/context/otp-context";
import AppRoutes from "./shared/routes/AppRoutes";
import { FormDataProvider } from "./store/context/form-data-context";
import { NotificationProvider } from "./store/context/notification-context";

import { WebSocketProvider } from "./shared/utils/singalr";
import { DashboardProvider } from "./store/context/DashboardContext";
import { BrowserRouter } from "react-router-dom";

const MyApp = () => {

  const { theme: selectedTheme } = useTheme();

  const lightTheme = selectedTheme === "light";

  const subdomain = getSubdomain();

  if (subdomain == 'citygate') {
    document.title = `Welcome to City Gate Investment`;
  } else {
    document.title = 'Welcome to Empire Trust MFB';
  }



  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#e5791b",
        },
        algorithm: lightTheme ? theme.defaultAlgorithm : theme.darkAlgorithm,
      }}
    >
      <App>
        <NotificationProvider>
          <FormDataProvider>
            <OtpProvider>
              <BrowserRouter>
                <DashboardProvider>
                  <WebSocketProvider>

                    <AppRoutes />

                  </WebSocketProvider>
                </DashboardProvider>
              </BrowserRouter>
            </OtpProvider>
          </FormDataProvider>
        </NotificationProvider>
      </App>
    </ConfigProvider>
  );
};

export default MyApp;
