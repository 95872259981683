import { lazy } from "react";

const SharedProductRoutes = [
  {
    path: "education",
    element: lazy(() => import("../../components/Education/Education")),
  },
  {
    path: "goal-based",
    element: lazy(() => import("../../components/Goal Based/GoalBased")),
  },
  {
    path: "funeral-expense/select-customer-type",
    element: lazy(() => import("../../pages/FuneralExpensePages/CustomerType")),
  },
  {
    path: "funeral-expense",
    element: lazy(() =>
      import("../../pages/FuneralExpensePages/HandleCustomerSelection")
    ),
  },
  {
    path: "funeral-expense/quotation-details",
    element: lazy(() => import("../../pages/FuneralExpensePages/Quotation")),
  },
  {
    path: "group-life-assurance",
    element: lazy(() => import("../../pages/GroupLifeAssurance/GroupLife")),
  },
  {
    path: "group-life-assurance/quotation-details",
    element: lazy(() =>
      import("../../pages/GroupLifeAssurance/GroupLifeQuotation")
    ),
  },
  {
    path: "customer-type/critical-illness",
    element: lazy(() =>
      import("../../components/Group Critical Illness/CriticalIlness")
    ),
  },
  {
    path: "customer-type",
    element: lazy(() =>
      import("../../components/Group Critical Illness/CustomerType")
    ),
  },
  {
    path: "customer-type/critical-illness/critical-illness-quotation",
    element: lazy(() =>
      import("../../components/Group Critical Illness/CriticalIllnessQuotation")
    ),
  },
  {
    path: "customer-type/group-customer/critical-illness-quotation",
    element: lazy(() =>
      import("../../components/Group Critical Illness/CriticalIllnessQuotation")
    ),
  },
  {
    path: "/health-insurance/health-insurance-quotation",
    element: lazy(() =>
      import("../../components/Health Insurance/HealthInsuranceQuotation")
    ),
  },
  {
    path: "customer-type/critical-illness/submit",
    element: lazy(() =>
      import("../../components/Group Critical Illness/Submit")
    ),
  },
  {
    path: "customer-type/group-customer",
    element: lazy(() =>
      import("../../components/Group Critical Illness/GroupCustomer")
    ),
  },
  {
    path: "customer-type/group-customer/upload-details",
    element: lazy(() =>
      import("../../components/Group Critical Illness/UploadDetails")
    ),
  },
  {
    path: "confirm-details",
    element: lazy(() =>
      import("../../components/Group Critical Illness/ConfirmDetails")
    ),
  },
  {
    path: "confirm-details/request-confirmation",
    element: lazy(() =>
      import("../../components/Group Critical Illness/RequestConfirmation")
    ),
  },
  {
    path: "/request-confirmation",
    element: lazy(() =>
      import("../../components/Group Critical Illness/RequestConfirmation")
    ),
  },
  {
    path: "group-credit/*",
    element: lazy(() => import("../../components/Routes/GroupCreditRoutes")),
  },
  {
    path: "welcome",
    element: lazy(() => import("../../components/Group Term Life/Welcome")),
  },
  {
    path: "term-life-quote",
    element: lazy(() =>
      import("../../components/Group Term Life/TermLifeQuote")
    ),
  },
  {
    path: "Education/Educ-Quotation",
    element: lazy(() => import("../../components/Education/EducQuotation")),
  },
  {
    path: "Goal-based/goal-quotation",
    element: lazy(() => import("../../components/Goal Based/GoalQuotation")),
  },
  {
    path: "annuity",
    element: lazy(() => import("../../pages/AnnuityPages/Annuity")),
  },

   {
    path: "loan-request/:name",
    element: lazy(() => import("../../pages/Loan/Loans/LoanRequest")),
  },
  {
    path: "annuity/quotation-details",
    element: lazy(() => import("../../pages/AnnuityPages/Quotation")),
  },
  {
    path: "group-life-assurance/call-back-submission",
    element: lazy(() =>
      import("../../components/Group Life/SubmittedCallBackForm")
    ),
  },
  {
    path: "motor-vehicle/select-motor-use",
    element: lazy(() =>
      import("../../pages/MotorVehicleInsurance/VehicleCategory")
    ),
  },
  {
    path: "motor-vehicle",
    element: lazy(() =>
      import("../../pages/MotorVehicleInsurance/HandleMotorSelection")
    ),
  },
  {
    path: "motor-vehicle/quotation",
    element: lazy(() =>
      import("../../pages/MotorVehicleInsurance/MotorQuotation")
    ),
  },
  {
    path: "personal-customer",
    element: lazy(() =>
      import("../../components/Health Insurance/HealthCustomer")
    ),
  },
  {
    path: "health-insurance",
    element: lazy(() =>
      import("../../components/Health Insurance/HealthInsurance")
    ),
  },
];

export default SharedProductRoutes;
