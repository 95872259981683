import React, { useRef, useState, useEffect } from "react";
import {
  RightOutlined,
  LeftOutlined,
  CarOutlined,
  HomeOutlined,
  UserOutlined,
  HeartOutlined,
  BookOutlined,
} from "@ant-design/icons";
import { Carousel, Card, Empty } from "antd";
import { useNavigate } from "react-router-dom";
import "./styles/Dashboard.css";
import { useSelector } from "react-redux";
import { useTheme } from "../../store/context/theme-context";

const DashboardCarouselPrevArrow = ({ onClick }) => (
  <div
    className='absolute top-1/2 transform -translate-y-1/2 z-10 cursor-pointer w-10 h-10 rounded-full bg-[#e5791b] flex items-center justify-center left-[-22px]'
    onClick={onClick}
  >
    <LeftOutlined className='text-lg' />
  </div>
);

const DashboardCarouselNextArrow = ({ onClick }) => (
  <div
    className='absolute top-1/2 transform -translate-y-1/2 z-10 cursor-pointer w-10 h-10 rounded-full bg-[#e5791b] text-white flex items-center justify-center right-[-10px]'
    onClick={onClick}
  >
    <RightOutlined className='text-lg' />
  </div>
);

const PendingLoansCarousel = () => {
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [slidesToScroll, setSlidesToScroll] = useState(1);
  const [showArrows, setShowArrows] = useState(true);

  const navigate = useNavigate();
  const { theme } = useTheme();
  const policies = useSelector((state) => state.policy.pendingQuotes);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 480) {
        setSlidesToShow(1);
        setSlidesToScroll(1);
        setShowArrows(false);
      } else if (width < 768) {
        setSlidesToShow(2);
        setSlidesToScroll(2);
        setShowArrows(false);
      } else if (width < 1200) {
        setSlidesToShow(2);
        setSlidesToScroll(2);
        setShowArrows(false);
      } else {
        setSlidesToShow(3);
        setSlidesToScroll(3);
        setShowArrows(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleQuoteClick = (quote) => {
    navigate(`/${quote.policyNumber}`);
  };

  const handlePrevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
      setCurrentIndex(currentIndex - slidesToScroll);
    }
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
      setCurrentIndex(currentIndex + slidesToScroll);
    }
  };

  const getQuoteType = (type) => {
    switch (type) {
      case "personal":
        return "Personal Insurance";
      case "education":
        return "Education Insurance";
      case "motor":
        return "Motor Insurance";
      case "home":
        return "Home Insurance";
      case "medical":
        return "Medical Insurance";
      case "life":
        return "Life Insurance";
      default:
        return type;
    }
  };

  const getIconByType = (type) => {
    const iconColor = theme === "dark" ? "text-[#D3D3D3]" : "text-[#E5791B]";
    switch (type) {
      case "personal":
        return <UserOutlined className={`${iconColor} w-6 h-6`} />;
      case "education":
        return <BookOutlined className={`${iconColor} w-6 h-6`} />;
      case "motor":
        return <CarOutlined className={`${iconColor} w-6 h-6`} />;
      case "home":
        return <HomeOutlined className={`${iconColor} w-6 h-6`} />;
      case "medical":
        return <HeartOutlined className={`${iconColor} w-6 h-6`} />;
      default:
        return <UserOutlined className={`${iconColor} w-6 h-6`} />;
    }
  };

  return (
    <div
      className={`relative ${theme === "dark" ? "bg-dark text-white" : "bg-light text-black"}`}
    >

      {policies.length == 0 ? (
        <Empty description="No pending applications available" />
      ) : (
        <>
          <Carousel
            ref={carouselRef}
            draggable
            slidesToShow={slidesToShow}
            slidesToScroll={slidesToScroll}
            beforeChange={(current, next) => setCurrentIndex(next)}
            className="relative"
            dots={false}
          >
            {policies.map((quote) => (
              <div className="p-1" key={quote.policyNumber}>
                <Card
                  className={`rounded-lg p-1 shadow-md hover:cursor-pointer ${theme === "dark" ? "bg-gray-800 border-gray-600" : "bg-white border-gray-300"
                    }`}
                  bordered={false}
                  onClick={() => handleQuoteClick(quote)}
                >
                  <div className="flex items-center">
                    <div
                      className={`rounded-full p-4 mr-4 ${theme === "dark" ? "bg-gray-700" : "bg-gray-200"
                        }`}
                    >
                      {getIconByType(quote.type)}
                    </div>
                    <div className="flex-1 overflow-hidden">
                      <div
                        className={`font-semibold text-base truncate ${theme === "dark" ? "text-gray-100" : "text-gray-800"
                          }`}
                        title={getQuoteType(quote.type)}
                      >
                        {getQuoteType(quote.type)}
                      </div>
                      <div
                        className={`${theme === "dark" ? "text-gray-400" : "text-gray-600"
                          } text-sm`}
                      >
                        {quote.coverType}
                      </div>
                      <div
                        className={`${theme === "dark" ? "text-gray-400" : "text-gray-600"
                          } text-sm`}
                      >
                        {quote.type} cover
                      </div>
                      <div
                        className={`${theme === "dark" ? "text-gray-400" : "text-gray-600"
                          } text-sm`}
                      >
                        {parseFloat(quote.totalPremium).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        KES
                      </div>
                    </div>
                  </div>
                  <div className="absolute top-1/2 right-2 transform -translate-y-1/2">
                    <RightOutlined
                      className={`text-xl ${theme === "dark" ? "text-gray-100" : "text-[#E5791B]"
                        }`}
                    />
                  </div>
                </Card>
              </div>
            ))}
          </Carousel>
          {showArrows && (
            <>
              {currentIndex > 0 && <DashboardCarouselPrevArrow onClick={handlePrevClick} />}
              {currentIndex < policies.length - slidesToShow && (
                <DashboardCarouselNextArrow onClick={handleNextClick} />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PendingLoansCarousel;
