import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import NotFound from "../../pages/NotFound";
import AuthLayout from "../../layout/auth-layout/AuthLayout";
import InitialPage from "../../pages/InitialPage";
import Home from "../../pages/Home";
import Dashboard from "../../components/Dashboard/Dashboard";
import LoadingPage from "../common/Loader";
import ForgotPasswordPage from "../../authentication/pages/ForgotPassword";
import OTPPage from "../../authentication/pages/OTP";
import ProtectedLayout from "../../utils/routes/ProtectedLayout";
import SharedProductRoutes from "./SharedProductRoutes";
import {
  ProtectedUserRoutes,
  ProtectedAdminRoutes,
  ProtectedSharedRoutes,
} from "./ProtectedInnerRoutes";
import ResetPasswordPage from "../../authentication/pages/ResetPassword";
import OtpVerificationPage from "../otp-verification/OtpVerification";
import AdminProtectedLayout from "../../utils/routes/AdminProtectedLayout";

const Privacy = lazy(() => import("../../pages/TermsAndPrivacy/Privacy"));
const Terms = lazy(() => import("../../pages/TermsAndPrivacy/Terms"));
const Authentication = lazy(() => import("../../authentication/pages/Auth"));

const AppRoutesCopy = () => {
  return (
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          {/* Landing Page Route */}
          <Route path="/segment" element={<InitialPage />} />

          {/* PortalLayout path */}
          <Route path="/" element={<ProtectedLayout element={Home} />} />

          {/* InnerLayout path */}
          <Route
            path="/dashboard/*"
            element={<ProtectedLayout element={Dashboard} />}
          />
          {/* <Route
            path="/admin-dashboard/*"
            element={<ProtectedLayout element={Dashboard} />}
          /> */}

          {/* Shared Routes between InnerLayout and PortalLayout */}
          {SharedProductRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<ProtectedLayout element={route.element} />}
            />
          ))}

          {/* Protected Inner Routes*/}
          {ProtectedUserRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<ProtectedLayout element={route.element} />}
            />
          ))}

          {/* Protected Admin Routes */}
          {ProtectedAdminRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<AdminProtectedLayout element={route.element} />}
            />
          ))}
          {/* Protected Shared Routes*/}
          {ProtectedSharedRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<ProtectedLayout element={route.element} />}
            />
          ))}

          {/* Auth Routes */}
          <Route path="/authentication/*" element={<AuthRoutes />} />
          <Route path="/admin/authentication/*" element={<AuthRoutes />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route
            path="/forgot-password/otp-verify"
            element={<OtpVerificationPage />}
          />

          {/* Admin Routes */}
          <Route
            path="/admin"
            element={
              <Navigate
                to="/admin/authentication/?mode=signin"
                replace={true}
              />
            }
          />

          {/* 404 Not Found Route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
  );
};

const AuthRoutes = () => {
  return (
    <AuthLayout>
      <Suspense
        fallback={
          <div>
            <LoadingPage />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Authentication />} />
          <Route path="/otp" element={<OTPPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        </Routes>
      </Suspense>
    </AuthLayout>
  );
};

export default AppRoutesCopy;
