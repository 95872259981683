import { useState } from "react";
import { Layout } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import Sidebar from "../main-layout/Sidebar";
import { Navigate, useLocation } from "react-router-dom";
import { getSessionData } from "../../utils/interceptors/localStorageService";
import { ProtectedSharedRoutes } from "../../shared/routes/ProtectedInnerRoutes";

const { Content, Sider } = Layout;

const InnerLayout = ({ children }) => {
  const location = useLocation();
  const sessionData = getSessionData();
  const [selectedKey, setSelectedKey] = useState(
    localStorage.getItem("selectedKey") || "home"
  );
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  const isAuthenticated = !!sessionData; // Check if user is logged in
  const isAdmin = sessionData?.userClassification === 2; // Check if user is admin

  const isSharedRoute = ProtectedSharedRoutes.some(
    (route) => route.path === location.pathname
  ); // Check if the route is a shared route between authenticated users and admin

  const handleMenuSelect = (key) => {
    if (key === "theme" || key === "expanded" || key === "collapsed") return;
    setSelectedKey(key);
    localStorage.setItem("selectedKey", key);
  };

  const toggleCollapsed = () => {
    setCollapsed((prev) => !prev);
  };

  const handleResize = () => {
    const isSmall = window.innerWidth < 768;
    setIsSmallScreen(isSmall);
    setCollapsed(isSmall);
  };

  // Redirect to login if user is not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/authentication?mode=signin" replace />;
  }

  // If the user is an admin and trying to access non-shared user route, redirect to admin dashboard
  if (isAdmin && !isSharedRoute) {
    return <Navigate to="/admin-dashboard" replace />;
  }

  return (
    <Layout hasSider>
      {/* Sidebar Sider */}
      <Sider
        collapsed={collapsed}
        onCollapse={setCollapsed}
        width={270}
        collapsedWidth={0}
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 1000,
          transition: collapsed ? "left 0.3s ease" : "none",
        }}
        breakpoint="md"
        onBreakpoint={(broken) => {
          setCollapsed(broken);
        }}
      >
        <Sidebar
          selectedKey={selectedKey}
          onSelect={handleMenuSelect}
          collapsed={collapsed}
          type="inner"
        />
      </Sider>

      {/* MenuOutlined icon for toggling sidebar on small screens */}
      {collapsed && isSmallScreen && (
        <MenuOutlined
          style={{
            position: "fixed",
            top: 20,
            left: 20,
            zIndex: 1200,
            cursor: "pointer",
          }}
          onClick={toggleCollapsed}
        />
      )}

      {/* Backdrop */}
      {!collapsed && isSmallScreen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: 900,
          }}
          onClick={toggleCollapsed}
        />
      )}

      {/* Main Layout */}
      <Layout
        style={{
          marginLeft: isSmallScreen ? 0 : 270,
          transition: "margin-left 0.2s ease",
        }}
      >
        {/* Main Content */}
        <Content
          style={{
            overflow: "auto",
            padding: "24px",
          }}
        >
          <div className="min-h-[100vh] h-auto">{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default InnerLayout;
