import dayjs from "dayjs";

export const formatCurrencyWithCode = (amount) => {
  if (amount) {
    return `NGN ${new Intl.NumberFormat("en-NG", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount)}`;
  } else {
    return amount;
  }
};

export const formatCurrencyWithoutCode = (amount) => {
  if (amount) {
    return new Intl.NumberFormat("en-NG", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  } else {
    return amount;
  }
};

export const formatDate = (date) => {
  if (date) {
    return dayjs(date).format("D-MM-YYYY");
  } else {
    return date;
  }
};

export const cleanObject = (obj, keysToExclude = []) => {
  console.log("key", keysToExclude);
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key, value]) =>
        value !== "" && value != null && !keysToExclude.includes(key)
    )
  );
};
