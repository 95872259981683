import { GlobalOutlined, DownOutlined } from "@ant-design/icons";
import LanguageSwitcher from "./LanguageSwitcher";
import { useLanguage } from "../../store/context/language-context";

export const languageMap = {
  en: "English",
  sw: "Swahili",
  fr: "Français",
  rw: "Kinyarwanda",
};

const LanguageSelector = () => {
  const { language: languageCode, showModal } = useLanguage();

  const languageName = languageMap[languageCode];

  return (
    <>
      <div
        className="flex items-center justify-between max-w-[200px] cursor-pointer"
        onClick={showModal}
      >
        <div className="flex items-center justify-start gap-3">
          <div
            className="flex items-center justify-center rounded-full bg-gray-300"
            style={{ padding: "8px" }}
          >
            <GlobalOutlined className="text-xl text-[#E5791B]" />
          </div>
          <p>{languageName}</p>
        </div>
        <DownOutlined className="text-[#E5791B]" />
      </div>

      <LanguageSwitcher />
    </>
  );
};

export default LanguageSelector;
