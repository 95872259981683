import { postRequest } from "../../../utils/api/requests/request";

export const SET_USER_LOGIN = "SET_USER_LOGIN";
export const LOGOUT_USER = "LOGOUT_USER";

export const setUserLogin = (userData) => {
  return async (dispatch) => {
    try {
      const command = "SelfLoginCommand";
      const sessionData = await postRequest( command, userData);
      dispatch({
        type: SET_USER_LOGIN,
        payload: sessionData.outData.data,
      });

      return Promise.resolve(sessionData);
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const logoutUser = () => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_USER,
    });
  };
};
