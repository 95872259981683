import { useState, useEffect } from "react";
import { Layout } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import { getSessionData } from "../../utils/interceptors/localStorageService";

const { Content, Sider } = Layout;

const PortalLayout = ({ children }) => {
  const navigate = useNavigate();
  const sessionData = getSessionData();
  const [selectedKey, setSelectedKey] = useState(
    localStorage.getItem("selectedKey") || "home"
  );
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  const handleMenuSelect = (key) => {
    if (key === "theme" || key === "expanded" || key === "collapsed") return;
    setSelectedKey(key);
    localStorage.setItem("selectedKey", key);
  };

  const toggleCollapsed = () => {
    setCollapsed((prev) => !prev);
  };

  const handleResize = () => {
    const isSmall = window.innerWidth < 768;
    setIsSmallScreen(isSmall);
    setCollapsed(isSmall);
  };

  useEffect(() => {
    if (sessionData) {
      sessionData?.userClassification === 2
        ? navigate("/admin-dashboard")
        : navigate("/dashboard");
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [sessionData, navigate]);

  return (
    <Layout hasSider>
      {/* Sidebar Sider */}
      <Sider
        collapsed={collapsed}
        onCollapse={setCollapsed}
        width={270}
        collapsedWidth={0}
        style={{
          overflow: "hidden",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 1000, // Keep the sidebar always above the content and backdrop
          transition: "left 0.3s ease",
        }}
        breakpoint="md"
        onBreakpoint={(broken) => {
          setCollapsed(broken);
          setIsSmallScreen(broken);
        }}
      >
        <Sidebar
          selectedKey={selectedKey}
          onSelect={handleMenuSelect}
          collapsed={collapsed}
          type="portal"
        />
      </Sider>

      {/* MenuOutlined icon for toggling sidebar on small screens */}
      {collapsed && isSmallScreen && (
        <MenuOutlined
          style={{
            position: "fixed",
            top: 20,
            left: 20,
            zIndex: 1200,
            cursor: "pointer",
          }}
          onClick={toggleCollapsed}
        />
      )}

      {/* Backdrop */}
      {!collapsed && isSmallScreen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: 900,
          }}
          onClick={toggleCollapsed}
        />
      )}

      {/* Main Layout */}
      <Layout
        style={{
          marginLeft: isSmallScreen ? 0 : 270,
          transition: "margin-left 0.2s ease",
        }}
      >
        {/* Main Content */}
        <Content
          style={{
            overflow: "auto",
            padding: "24px",
          }}
        >
          <div className="min-h-[100vh] h-auto">{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default PortalLayout;
