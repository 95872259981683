import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { groupCreditReducer } from "./reducers/groupCreditReducers";
import { authReducer } from "./features/authSlice";
import { funeralExpenseReducer } from "./features/gleSlice";
import { groupLifeAssuranceReducer } from "./features/glaSlice";
import { groupCriticalIllnessReducer } from "./features/gciSlice";
import { educationReducer } from "./features/eduSlice";
import { goalbasedReducer } from "./features/goalSlice";
import { callBackReducer } from "./features/callBackSlice";
import motorVehicleReducer from "./features/motorSlice";
import { healthInsuranceReducer } from "./features/healthSlice";
import userReducer from "./reducers/userReducer";
import policyReducer from "./reducers/policyReducer";
import paymentReducer from "./reducers/paymentReducer";

const rootReducer = combineReducers({
  groupCredit: groupCreditReducer,
  auth: authReducer,
  funeralExpense: funeralExpenseReducer,
  groupLifeAssurance: groupLifeAssuranceReducer,
  groupCriticalIllness: groupCriticalIllnessReducer,
  education: educationReducer,
  goalBased: goalbasedReducer,
  callBack: callBackReducer,
  motorVehicle: motorVehicleReducer,
  healthInsurance: healthInsuranceReducer,
  user: userReducer,
  policy: policyReducer,
  payment: paymentReducer
});

export const store = configureStore({
  reducer: rootReducer,
});
