import { useState } from "react";
import { Modal, Button } from "antd";
import CountrySupport from "../../components/Settings/Personal Settings/CountrySupport";
import { countryData } from "../../utils/data/countryData";
import CountryContacts from "./CountryContacts";

const CountrySupportModal = ({ isModalOpen, handleOkay, handleCancel }) => {
  const [country, setCountry] = useState({});
  const [isSupportOpen, setIsSupportOpen] = useState(false);

  const handleClick = (country) => {
    setCountry(country);
  };

  const handleOk = () => {
    setIsSupportOpen(false);
    setCountry({});
  };

  const handleSupportModal = () => {
    setIsSupportOpen(true);
    handleOkay();
  };

  return (
    <>
      <Modal
        title={<p className="text-center">Support</p>}
        open={isModalOpen}
        onOk={handleOkay}
        onCancel={handleCancel}
        footer={[
          <div className="w-full" key="footer-row">
            <div className="flex justify-between items-center h-full gap-3 mb-5">
              <Button
                key="close"
                onClick={handleCancel}
                className="h-full w-full px-6 py-2 border-[#E5791B] text-[#E5791B] shadow-none text-center text-base"
              >
                Cancel
              </Button>
              <Button
                key="continue"
                type="primary"
                disabled={!country.name}
                onClick={handleSupportModal}
                className="h-full w-full px-6 py-2 shadow-none text-center text-base"
              >
                Continue
              </Button>
            </div>
          </div>,
        ]}
        width="80vw"
      >
        <>
          <div className="mb-5">
            <p className="text-lg font-semibold mb-1">Where are you from?</p>
            <p className="text-[#929497] text-left">
              Select your country to get personalized assistance
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            {countryData.map((item) => (
              <CountrySupport
                key={item.id}
                country={item}
                isSelected={item.name === country.name}
                onCardClick={handleClick}
              />
            ))}
          </div>
        </>
      </Modal>

      <CountryContacts
        isOpen={isSupportOpen}
        onOpen={handleOk}
        onCancel={handleOk}
        selectedCountry={country}
      />
    </>
  );
};

export default CountrySupportModal;
