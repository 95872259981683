import sspFlag from "../../assets/flags/ssp.png";
import njaFlag from "../../assets/flags/9ja.png";
import cdfFlag from "../../assets/flags/cdf.png";
import rwfFlag from "../../assets/flags/rwf.png";
import kesFlag from "../../assets/flags/kes.png";
import tzsFlag from "../../assets/flags/tzs.png";
import ugxFlag from "../../assets/flags/ugx.png";

export const countryData = [
  {
    id: 1,
    name: "Nigeria",
    flag: njaFlag,
    phone: "+234 911 9986 000",
    email: "info-nigeria@equitybank.co.ke",
    facebookLink: "https://www.facebook.com/SsEquityBank/",
    websiteLink: "https://www.ss.equitybankgroup.com/",
  },
  {
    id: 1,
    name: "South Sudan",
    flag: sspFlag,
    phone: "+211 916 986 000",
    email: "info-southsudan@equitybank.co.ke",
    facebookLink: "https://www.facebook.com/SsEquityBank/",
    websiteLink: "https://www.ss.equitybankgroup.com/",
  },
  {
    id: 2,
    name: "Congo (Democratic Republic of Congo)",
    flag: cdfFlag,
    phone: "+243 996 018 000",
    email: "mail@equitybcdc.cd",
    twitterLink: "https://x.com/EquityBCDC?lang=en",
    facebookLink: "https://www.facebook.com/EquityBCDC/",
    linkedinLink: "https://www.linkedin.com/company/equitybcdc/",
    websiteLink: "https://equitygroupholdings.com/cd/",
  },
  {
    id: 3,
    name: "Rwanda",
    flag: rwfFlag,
    phone: "+250 788 190 000",
    email: "inforwanda@equitybank.co.rw",
    twitterLink: "https://x.com/kwequitybank?lang=en",
    facebookLink: "https://www.facebook.com/RwEquityBank/?ref=page_internal",
    websiteLink: "https://equitygroupholdings.com/rw/",
  },
  {
    id: 4,
    name: "Kenya",
    flag: kesFlag,
    phone: "+254 763 000 000",
    email: "info@equitybank.co.ke",
    twitterLink: "https://x.com/keequitybank?lang=en",
    facebookLink: "https://www.facebook.com/KeEquityBank/?ref=page_internal",
    linkedinLink:
      "https://www.linkedin.com/company/equity-bank-limited/?originalSubdomain=ke",
    websiteLink: "https://equitygroupholdings.com/",
  },
  {
    id: 5,
    name: "Tanzania",
    flag: tzsFlag,
    phone: "+255 768 985 500",
    email: "infotz@equitybank.co.tz",
    twitterLink: "https://x.com/TZEquityBank?lang=en",
    facebookLink: "https://www.facebook.com/TzEquityBank/",
    websiteLink: "https://equitygroupholdings.com/tz/",
  },
  {
    id: 6,
    name: "Uganda",
    flag: ugxFlag,
    phone: "+256 312 327 000",
    email: "info@equitybank.co.ug",
    twitterLink: "https://x.com/UgEquityBank?lang=en",
    facebookLink: "https://www.facebook.com/UgEquityBank/",
    websiteLink: "https://equitygroupholdings.com/ug/",
  },
];



export const PhoneAreas = [
  { code: "+234", flag: njaFlag, country: "Nigeria" },
  { code: "+211", flag: sspFlag, country: "South Sudan" },
  { code: "+243", flag: cdfFlag, country: "DRC" },
  { code: "+250", flag: rwfFlag, country: "Rwanda" },
  { code: "+254", flag: kesFlag, country: "Kenya" },
  { code: "+255", flag: tzsFlag, country: "Tanzania" },
  { code: "+256", flag: ugxFlag, country: "Uganda" },
];


export const productData = [
  {
    "id": 1,
    "name": "Payroll Loan I",
    "code": "000p45",
    "interestRateCalculationPeriod": 1,
    "interestRateCalculationDescription": "Weekly",
    "interestRateCalculationsType": 0,
    "interestRateCalculationsTypeDescription": "Flat",
    "currencyCode": "NGN",
    "interestRate": 23,
    "disbursementFeeCalculation": 0,
    "disbursementFeeCalculationDescription": "Flat",
    "disbursementFee": 30000,
    "minimumAmount": null,
    "maximumAmount": null,
    "maximumTenorInDays": 30,
    "createdAt": "2024-10-23T13:13:32.8273173"
  },
  {
    "id": 2,
    "name": "Payroll Loan",
    "code": "000p4",
    "interestRateCalculationPeriod": 0,
    "interestRateCalculationDescription": "Daily",
    "interestRateCalculationsType": 0,
    "interestRateCalculationsTypeDescription": "Flat",
    "currencyCode": "NGN",
    "interestRate": 0,
    "disbursementFeeCalculation": 0,
    "disbursementFeeCalculationDescription": "Flat",
    "disbursementFee": 0,
    "minimumAmount": null,
    "maximumAmount": null,
    "maximumTenorInDays": 30,
    "createdAt": "2024-10-23T13:13:33.0474379"
  },
  {
    "id": 3,
    "name": "SMEs Loan",
    "code": "000p094",
    "interestRateCalculationPeriod": 0,
    "interestRateCalculationDescription": "Daily",
    "interestRateCalculationsType": 0,
    "interestRateCalculationsTypeDescription": "Flat",
    "currencyCode": "NGN",
    "interestRate": 0,
    "disbursementFeeCalculation": 0,
    "disbursementFeeCalculationDescription": "Flat",
    "disbursementFee": 0,
    "minimumAmount": null,
    "maximumAmount": null,
    "maximumTenorInDays": 300,
    "createdAt": "2024-10-23T13:38:47.250802"
  },
  {
    "id": 4,
    "name": "SMEs Loan",
    "code": "000p094",
    "interestRateCalculationPeriod": 0,
    "interestRateCalculationDescription": "Daily",
    "interestRateCalculationsType": 0,
    "interestRateCalculationsTypeDescription": "Flat",
    "currencyCode": "NGN",
    "interestRate": 0,
    "disbursementFeeCalculation": 0,
    "disbursementFeeCalculationDescription": "Flat",
    "disbursementFee": 0,
    "minimumAmount": null,
    "maximumAmount": null,
    "maximumTenorInDays": 300,
    "createdAt": "2024-10-23T13:38:47.2614081"
  },
  {
    "id": 5,
    "name": "Start Up",
    "code": "STR-2938",
    "interestRateCalculationPeriod": 1,
    "interestRateCalculationDescription": "Weekly",
    "interestRateCalculationsType": 0,
    "interestRateCalculationsTypeDescription": "Flat",
    "currencyCode": "NGN",
    "interestRate": 20,
    "disbursementFeeCalculation": 0,
    "disbursementFeeCalculationDescription": "Flat",
    "disbursementFee": 34,
    "minimumAmount": 12000,
    "maximumAmount": 300000,
    "maximumTenorInDays": 30,
    "createdAt": "2024-10-24T18:38:48.6441462"
  },
  {
    "id": 6,
    "name": "Entrepreneur Loan",
    "code": "ENT-244BB",
    "interestRateCalculationPeriod": 0,
    "interestRateCalculationDescription": "Daily",
    "interestRateCalculationsType": 0,
    "interestRateCalculationsTypeDescription": "Flat",
    "currencyCode": "NGN",
    "interestRate": 26,
    "disbursementFeeCalculation": 1,
    "disbursementFeeCalculationDescription": "Percentage (Loan Amount)",
    "disbursementFee": 125000,
    "minimumAmount": null,
    "maximumAmount": null,
    "maximumTenorInDays": 14,
    "createdAt": "2024-10-25T09:35:17.2009329"
  },
  {
    "id": 7,
    "name": "Entrepreneur Loan II",
    "code": "ENT-514F",
    "interestRateCalculationPeriod": 3,
    "interestRateCalculationDescription": "Yearly",
    "interestRateCalculationsType": 3,
    "interestRateCalculationsTypeDescription": "3",
    "currencyCode": "NGN",
    "interestRate": 50,
    "disbursementFeeCalculation": 1,
    "disbursementFeeCalculationDescription": "Percentage (Loan Amount)",
    "disbursementFee": 70,
    "minimumAmount": 10000,
    "maximumAmount": 400000,
    "maximumTenorInDays": 421,
    "createdAt": "2024-10-25T19:35:26.1617344"
  },
  {
    "id": 8,
    "name": "Entrepreneur Loan III",
    "code": "ENT-214M",
    "interestRateCalculationPeriod": 3,
    "interestRateCalculationDescription": "Yearly",
    "interestRateCalculationsType": 2,
    "interestRateCalculationsTypeDescription": "2",
    "currencyCode": "NGN",
    "interestRate": 25,
    "disbursementFeeCalculation": 1,
    "disbursementFeeCalculationDescription": "Percentage (Loan Amount)",
    "disbursementFee": 75,
    "minimumAmount": 20005,
    "maximumAmount": 120005,
    "maximumTenorInDays": 45,
    "createdAt": "2024-10-25T22:02:18.5005685"
  },
  {
    "id": 9,
    "name": "Entrepreneur Loan IV",
    "code": "ENT-324B",
    "interestRateCalculationPeriod": 2,
    "interestRateCalculationDescription": "Monthly",
    "interestRateCalculationsType": 2,
    "interestRateCalculationsTypeDescription": "2",
    "currencyCode": "NGN",
    "interestRate": 20,
    "disbursementFeeCalculation": 1,
    "disbursementFeeCalculationDescription": "Percentage (Loan Amount)",
    "disbursementFee": 43,
    "minimumAmount": 5000,
    "maximumAmount": 120000,
    "maximumTenorInDays": 15,
    "createdAt": "2024-10-25T22:25:51.9499208"
  },
  {
    "id": 10,
    "name": "Entrepreneur Loan V",
    "code": "ENT-024B",
    "interestRateCalculationPeriod": 2,
    "interestRateCalculationDescription": "Monthly",
    "interestRateCalculationsType": 1,
    "interestRateCalculationsTypeDescription": "ReducingBalance",
    "currencyCode": "NGN",
    "interestRate": 21,
    "disbursementFeeCalculation": 2,
    "disbursementFeeCalculationDescription": "Percentage (Disbursed Amount)",
    "disbursementFee": 31,
    "minimumAmount": 12001,
    "maximumAmount": 340001,
    "maximumTenorInDays": 51,
    "createdAt": "2024-10-25T22:59:52.2880545"
  },
  {
    "id": 11,
    "name": "Entrepreneur Loan VI",
    "code": "ENT-314B",
    "interestRateCalculationPeriod": 1,
    "interestRateCalculationDescription": "Weekly",
    "interestRateCalculationsType": 1,
    "interestRateCalculationsTypeDescription": "ReducingBalance",
    "currencyCode": "NGN",
    "interestRate": 20,
    "disbursementFeeCalculation": 1,
    "disbursementFeeCalculationDescription": "Percentage (Loan Amount)",
    "disbursementFee": 56,
    "minimumAmount": 11000,
    "maximumAmount": 3200000,
    "maximumTenorInDays": 45,
    "createdAt": "2024-10-26T02:13:51.9084625"
  },
  {
    "id": 12,
    "name": "Entrepreneur Loan VII",
    "code": "ENT-231B",
    "interestRateCalculationPeriod": 2,
    "interestRateCalculationDescription": "Monthly",
    "interestRateCalculationsType": 2,
    "interestRateCalculationsTypeDescription": "2",
    "currencyCode": "NGN",
    "interestRate": 20,
    "disbursementFeeCalculation": 1,
    "disbursementFeeCalculationDescription": "Percentage (Loan Amount)",
    "disbursementFee": 40,
    "minimumAmount": 32000,
    "maximumAmount": 200000,
    "maximumTenorInDays": 12,
    "createdAt": "2024-10-28T10:50:05.3301435"
  },
  {
    "id": 13,
    "name": "Entrepreneur Loan VIII",
    "code": "ENT-225E",
    "interestRateCalculationPeriod": 1,
    "interestRateCalculationDescription": "Weekly",
    "interestRateCalculationsType": 1,
    "interestRateCalculationsTypeDescription": "ReducingBalance",
    "currencyCode": "NGN",
    "interestRate": 40,
    "disbursementFeeCalculation": 0,
    "disbursementFeeCalculationDescription": "Flat",
    "disbursementFee": 2000000,
    "minimumAmount": 100000,
    "maximumAmount": 3000000,
    "maximumTenorInDays": 54,
    "createdAt": "2024-10-29T08:29:16.4920684"
  }
];


const documentTyps =
[
    { "name": "CUSTOM", "key": 0 },
    { "name": "NATIONAL ID", "key": 1 },
    { "name": "INTERNATIONAL PASSPORT", "key": 2 },
    { "name": "DRIVERS LICENCE", "key": 3 },
    { "name": "UTILITY BILL", "key": 4 },
    { "name": "BANK STATEMENT", "key": 5 },
    { "name": "COMPANY REGISTRATION", "key": 6 },
    { "name": "TAX CLEARANCE", "key": 7 },
    { "name": "CERTIFICATE OF INCORPORATION", "key": 8 },
    { "name": "MEMORANDUM OF ASSOCIATION", "key": 9 },
    { "name": "ARTICLES OF ASSOCIATION", "key": 10 },
    { "name": "BOARD RESOLUTION", "key": 11 },
    { "name": "IDENTITY CARD", "key": 12 },
    { "name": "SIGNATURE", "key": 13 },
    { "name": "PHOTOGRAPH", "key": 14 },
    { "name": "UNIVERISTY CERTIFICATE", "key": 15 },
    { "name": "BIRTH CERTIFICATE", "key": 16 },
    { "name": "MARRIAGE CERTIFICATE", "key": 17 },
    { "name": "DEATH CERTIFICATE", "key": 18 },
    { "name": "WILL", "key": 19 },
    { "name": "RESIDENCE PERMIT", "key": 20 },
    { "name": "WORK PERMIT", "key": 21 },
    { "name": "VISA", "key": 22 },
    { "name": "PERMANENT RESIDENCE", "key": 23 }
];


export const accountDocumentTyps =
[
    { "name": "NATIONAL ID", "key": 1 },
    { "name": "INTERNATIONAL PASSPORT", "key": 2 },
    { "name": "DRIVERS LICENCE", "key": 3 },

];