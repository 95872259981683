import axios from "axios";
import axiosInstance from "../../interceptors/axiosInstance";

const baseUrl = "https://apitesting.borderlesspay.co.uk/Fintech.CBS.Backend";

// Function to make a post request
export const postRequest = async (command, data) => {
  const endpoint = "/api/cmd/cmd";
  const dataToSend = {
    cmd: command,
    data: JSON.stringify(data),
  };

  try {
    const response = await axios.post(`${baseUrl}${endpoint}`, dataToSend);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// Function to make an authenticated post request
export const postAuthenticatedRequest = async (command, data) => {
  const endpoint = "/api/cmd/selfservice/cmd";
  const dataToSend = {
    cmd: command,
    data: JSON.stringify(data),
  };

  try {
    const response = await axiosInstance.post(endpoint, dataToSend);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
